import { Avatar, Chip, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { find, flatten, get, last, map, sum, sumBy } from 'lodash-es';
import React from 'react';
import { formatNumber } from '~/components/NumberText';
import { DAYS_PER_YEAR } from '~/constants/date';
import { formatUiDate } from '~/lib/date';
import { calculateDailyEnergy } from '~/models/common';
import { SiteClassification } from '~/models/meter';
import { EnergyPricingOfferTypes } from '~/models/project';
import { GreenBillingCalculation } from '~/models/retailer';
import {
  NoPageBreakBefore,
  PageBreak,
  Section,
  SectionContainer,
  StyledTableCell,
  StyledTableRow,
  Title
} from '~/pages/dashboard/project/EAReport/style';
import { TitleUnderline } from './style';
import { CalculatedProjectOfferPeriodsByName, EAReportGroupedMeter } from './types';

const SummarySection: React.FC<{ groupedMeters: EAReportGroupedMeter[] }> = ({ groupedMeters }) => {
  let isStepped = false;
  const groupedMetersWithBestOffer = map(groupedMeters, (groupedMeter) => {
    const bestOffer = find(groupedMeter.offers, (offer) => offer.isBest);
    const bestOfferPeriod =
      find(bestOffer?.periods, (period) => period.isBest) || ({} as CalculatedProjectOfferPeriodsByName);
    isStepped = bestOfferPeriod.offerType === EnergyPricingOfferTypes.STEPPED;
    return {
      ...groupedMeter,
      bestOffer,
      bestOfferPeriod
    };
  });
  return (
    <>
      <Section id="summary" sx={{ width: '100%', ...PageBreak }}>
        <SectionContainer sx={{ textAlign: 'center' }}>
          <Title variant="h4">Summary</Title>
          <TitleUnderline />
          <Typography variant="h5">All Contracts / Terms</Typography>
        </SectionContainer>
      </Section>

      <Section>
        <SectionContainer>
          <Typography variant="h6" gutterBottom>
            Current
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    State
                    <br />
                    /Group
                  </StyledTableCell>
                  <StyledTableCell>NMI</StyledTableCell>
                  <StyledTableCell>Suburb</StyledTableCell>
                  <StyledTableCell>Current Retailer</StyledTableCell>
                  <StyledTableCell>Contract End Date</StyledTableCell>
                  <StyledTableCell>Total kWh p.a</StyledTableCell>
                  <StyledTableCell>Current Rate c/kWh</StyledTableCell>
                  <StyledTableCell>Commodity Total (Annually)</StyledTableCell>
                  <StyledTableCell>Environmental & Retailer (Annually)</StyledTableCell>
                  <StyledTableCell>Current Contestable (Annually)</StyledTableCell>
                  <StyledTableCell>Network & Regulated Charges (Annually)</StyledTableCell>
                  <StyledTableCell>Total Current Expenditure (Annually)</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedMeters.map(({ meters, name }, index) => {
                  return (
                    <React.Fragment key={`site-${meters[0].state}-${index}`}>
                      {map(meters, (meter, index) => {
                        return (
                          <StyledTableRow key={meter.meterNumber}>
                            {index === 0 && (
                              <StyledTableCell rowSpan={meters.length}>{name || meter.state}</StyledTableCell>
                            )}
                            <StyledTableCell>{meter.meterNumber}</StyledTableCell>
                            <StyledTableCell>{meter.suburb}</StyledTableCell>
                            <StyledTableCell>{get(meter, 'contract.retailer.name')}</StyledTableCell>
                            <StyledTableCell>{formatUiDate(get(meter, 'contract.contractEndDate'))}</StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 'bold', bgcolor: 'grey.A400' }}>
                              {formatNumber(meter.annualData.totalEnergy, 0, '-')}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Chip
                                sx={{ mb: 0.5 }}
                                size="small"
                                avatar={<Avatar>P</Avatar>}
                                label={formatNumber(meter.contractPeriod.peakRate, 5, '-')}
                              />
                              <br />
                              <Chip
                                sx={{ mb: 0.5 }}
                                size="small"
                                avatar={<Avatar>OP</Avatar>}
                                label={formatNumber(meter.contractPeriod.offPeakRate, 5, '-')}
                              />
                              <br />
                              <Chip
                                size="small"
                                avatar={<Avatar>S</Avatar>}
                                label={formatNumber(meter.contractPeriod.shoulderRate, 5, '-')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>${formatNumber(meter.annualData.energyCost, 0, '-')}</StyledTableCell>
                            <StyledTableCell>
                              $
                              {formatNumber(
                                sum([
                                  meter.annualData.renewableCost,
                                  meter.annualData.annualRetailerFee,
                                  meter.annualData.annualMeteringCharge
                                ]),
                                0,
                                '-'
                              )}
                            </StyledTableCell>
                            <StyledTableCell>${formatNumber(meter.annualData.contestableCost, 0, '-')}</StyledTableCell>
                            <StyledTableCell>
                              $
                              {formatNumber(
                                sum([
                                  meter.annualData.networkStanding,
                                  meter.annualData.networkChargesEnergy,
                                  meter.annualData.networkChargesDemand,
                                  meter.annualData.regulatedCost
                                ]),
                                0,
                                '-'
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              $
                              {formatNumber(
                                sum([
                                  meter.annualData.contestableCost,
                                  meter.annualData.regulatedCost,
                                  meter.annualData.networkStanding,
                                  meter.annualData.networkChargesEnergy,
                                  meter.annualData.networkChargesDemand
                                ]),
                                0,
                                '-'
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}

                <TableRow sx={{ bgcolor: 'common.black' }}>
                  <StyledTableCell colSpan={4}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'brand.main', fontWeight: 'bold' }}>Total kWh</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    {formatNumber(sumBy(flatten(map(groupedMeters, 'meters')), 'annualData.totalEnergy'), 0, '-')}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'brand.main', fontWeight: 'bold' }}>Total</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(groupedMeters, ({ meters }) => sumBy(meters, (item) => sum([item.annualData.energyCost]))),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(groupedMeters, ({ meters }) =>
                        sumBy(meters, (item) =>
                          sum([
                            item.annualData.renewableCost,
                            item.annualData.annualRetailerFee,
                            item.annualData.annualMeteringCharge
                          ])
                        )
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(groupedMeters, ({ meters }) =>
                        sumBy(meters, (item) => sum([item.annualData.contestableCost]))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(groupedMeters, ({ meters }) =>
                        sumBy(meters, (item) =>
                          sum([
                            item.annualData.networkStanding,
                            item.annualData.networkChargesEnergy,
                            item.annualData.networkChargesDemand,
                            item.annualData.regulatedCost
                          ])
                        )
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(groupedMeters, ({ meters }) =>
                        sumBy(meters, (item) =>
                          sum([
                            item.annualData.contestableCost,
                            item.annualData.networkStanding,
                            item.annualData.networkChargesEnergy,
                            item.annualData.networkChargesDemand,
                            item.annualData.regulatedCost
                          ])
                        )
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <Typography variant="h6">Future</Typography>

          {isStepped && (
            <Typography variant="body2" color="textSecondary">
              (The presented stepped rate is the weighted average rate over the recommended period.)
            </Typography>
          )}

          <br />

          <TableContainer sx={NoPageBreakBefore}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    State
                    <br />
                    /Group
                  </StyledTableCell>
                  <StyledTableCell>NMI</StyledTableCell>
                  <StyledTableCell>Suburb</StyledTableCell>
                  <StyledTableCell>New Retailer</StyledTableCell>
                  <StyledTableCell>New Term</StyledTableCell>
                  <StyledTableCell>New Rate c/kWh</StyledTableCell>
                  <StyledTableCell>Commodity Total (Annually)</StyledTableCell>
                  <StyledTableCell>Environmental & Retailer (Annually)</StyledTableCell>
                  <StyledTableCell>New Contestable (Annually)</StyledTableCell>
                  <StyledTableCell>Network & Regulated Charges (Annually)</StyledTableCell>
                  <StyledTableCell>Total New Expenditure (Annually)</StyledTableCell>
                  <StyledTableCell>$ Change</StyledTableCell>
                  <StyledTableCell>% Change</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedMetersWithBestOffer.map(
                  ({ meters, lwaDLF, lwaMLF, bestOffer, bestOfferPeriod, name }, index) => {
                    const divide = 12 / Number(bestOfferPeriod?.periodName);
                    const numOfDays = sumBy(bestOfferPeriod.splitPeriods, 'numOfDays');
                    return (
                      <React.Fragment key={`site-${meters[0].state}-${index}`}>
                        {map(meters, (meter, index) => {
                          const lossFactor =
                            bestOffer?.greenBilling === GreenBillingCalculation.DLF_MLF
                              ? (meter.dlf ?? 1) * (meter.mlf ?? 1)
                              : meter.dlf ?? 1;
                          const energyCost =
                            (numOfDays / 100) *
                            sum([
                              calculateDailyEnergy(meter.annualData.peakEnergy) * (bestOfferPeriod.peak ?? 0),
                              calculateDailyEnergy(meter.annualData.offPeakEnergy) * (bestOfferPeriod.offPeak ?? 0),
                              calculateDailyEnergy(meter.annualData.shoulderEnergy) * (bestOfferPeriod.shoulder ?? 0)
                            ]) *
                            (meter.contract?.contractType === SiteClassification.TARIFF ? 1 : lwaDLF * lwaMLF);
                          const renewableCost =
                            sum([
                              bestOfferPeriod.aeeis ?? 0,
                              bestOfferPeriod.ess ?? 0,
                              bestOfferPeriod.lret ?? 0,
                              bestOfferPeriod.veet ?? 0,
                              bestOfferPeriod.sres ?? 0
                            ]) *
                            calculateDailyEnergy(meter.annualData.totalEnergy) *
                            lossFactor *
                            (numOfDays / 100);
                          const regulatedCost =
                            (sum([meter.ancillaryCharge ?? 0, meter.participantCharge ?? 0]) *
                              calculateDailyEnergy(meter.annualData.totalEnergy) *
                              numOfDays *
                              lossFactor) /
                            100;
                          const retailerFee =
                            (bestOffer?.dailyServiceFee || calculateDailyEnergy(meter.annualData.retailerFeePerMeter)) *
                            numOfDays *
                            meter.numOfMeters;
                          const meteringCharge =
                            (bestOffer?.dailyMeteringFee ||
                              calculateDailyEnergy(meter.annualData.meteringChargePerMeter)) *
                            numOfDays *
                            meter.numOfMeters;
                          const networkCharge =
                            (sum([
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand
                            ]) /
                              DAYS_PER_YEAR) *
                            numOfDays;
                          return (
                            <StyledTableRow key={meter.meterNumber}>
                              {index === 0 && (
                                <StyledTableCell rowSpan={meters.length}>{name || meter.state}</StyledTableCell>
                              )}
                              <StyledTableCell>{meter.meterNumber}</StyledTableCell>
                              <StyledTableCell>{meter.suburb}</StyledTableCell>
                              <StyledTableCell>{bestOffer?.retailer.name}</StyledTableCell>
                              <StyledTableCell>
                                {bestOfferPeriod?.periodName} <br />
                                <Typography variant="caption" fontStyle="italic" lineHeight="0.8">
                                  ({formatUiDate(bestOfferPeriod?.splitPeriods?.[0]?.startDate)}
                                  <br />-<br />
                                  {formatUiDate(last(bestOfferPeriod?.splitPeriods)?.endDate)})
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Chip
                                  sx={{ mb: 0.5 }}
                                  size="small"
                                  avatar={<Avatar>P</Avatar>}
                                  label={formatNumber(bestOfferPeriod.peak, 5, '-')}
                                />
                                <br />
                                <Chip
                                  sx={{ mb: 0.5 }}
                                  size="small"
                                  avatar={<Avatar>OP</Avatar>}
                                  label={formatNumber(bestOfferPeriod.offPeak, 5, '-')}
                                />
                                <br />
                                <Chip
                                  size="small"
                                  avatar={<Avatar>S</Avatar>}
                                  label={formatNumber(bestOfferPeriod.shoulder, 5, '-')}
                                />
                              </StyledTableCell>
                              <StyledTableCell>${formatNumber(energyCost * divide, 0, '-')}</StyledTableCell>
                              <StyledTableCell>
                                ${formatNumber(sum([renewableCost, retailerFee, meteringCharge]) * divide, 0, '-')}
                              </StyledTableCell>
                              <StyledTableCell>
                                $
                                {formatNumber(
                                  sum([energyCost, renewableCost, retailerFee, meteringCharge]) * divide,
                                  0,
                                  '-'
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                ${formatNumber(sum([networkCharge, regulatedCost]) * divide, 0, '-')}
                              </StyledTableCell>
                              <StyledTableCell>
                                $
                                {formatNumber(
                                  sum([
                                    energyCost,
                                    regulatedCost,
                                    renewableCost,
                                    retailerFee,
                                    meteringCharge,
                                    networkCharge
                                  ]) * divide,
                                  0,
                                  '-'
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  bgcolor:
                                    sum([
                                      energyCost,
                                      regulatedCost,
                                      renewableCost,
                                      retailerFee,
                                      meteringCharge,
                                      networkCharge
                                    ]) *
                                      divide >
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])
                                      ? '#ef9a9a'
                                      : '#a5d6a7',
                                  color:
                                    sum([
                                      energyCost,
                                      regulatedCost,
                                      renewableCost,
                                      retailerFee,
                                      meteringCharge,
                                      networkCharge
                                    ]) *
                                      divide >
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])
                                      ? '#c62828'
                                      : '#1b5e20'
                                }}
                              >
                                $
                                {formatNumber(
                                  sum([
                                    energyCost,
                                    regulatedCost,
                                    renewableCost,
                                    retailerFee,
                                    meteringCharge,
                                    networkCharge
                                  ]) *
                                    divide -
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ]),
                                  0,
                                  '-'
                                )}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{
                                  bgcolor:
                                    sum([
                                      energyCost,
                                      regulatedCost,
                                      renewableCost,
                                      retailerFee,
                                      meteringCharge,
                                      networkCharge
                                    ]) *
                                      divide >
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])
                                      ? '#ef9a9a'
                                      : '#a5d6a7',
                                  color:
                                    sum([
                                      energyCost,
                                      regulatedCost,
                                      renewableCost,
                                      retailerFee,
                                      meteringCharge,
                                      networkCharge
                                    ]) *
                                      divide >
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])
                                      ? '#c62828'
                                      : '#1b5e20'
                                }}
                              >
                                {formatNumber(
                                  ((sum([
                                    energyCost,
                                    regulatedCost,
                                    renewableCost,
                                    retailerFee,
                                    meteringCharge,
                                    networkCharge
                                  ]) *
                                    divide -
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])) /
                                    sum([
                                      meter.annualData.contestableCost,
                                      meter.annualData.networkStanding,
                                      meter.annualData.networkChargesEnergy,
                                      meter.annualData.networkChargesDemand,
                                      meter.annualData.regulatedCost
                                    ])) *
                                    100,
                                  2,
                                  '-'
                                )}
                                %
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                )}

                <TableRow sx={{ bgcolor: 'common.black' }}>
                  <StyledTableCell colSpan={5}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'brand.main', fontWeight: 'bold' }}>Total</StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) => item.energyCost) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) =>
                            sum([item.renewableCost, item.retailerServiceFee, item.meteringCharge])
                          ) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) =>
                            sum([item.energyCost, item.renewableCost, item.retailerServiceFee, item.meteringCharge])
                          ) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) => sum([item.regulatedCost, item.networkCost])) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: 'bold',
                      bgcolor:
                        sumBy(
                          groupedMetersWithBestOffer,
                          ({ bestOfferPeriod }) =>
                            sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                            (12 / Number(bestOfferPeriod?.periodName))
                        ) >
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )
                          ? '#ef9a9a'
                          : '#a5d6a7',
                      color:
                        sumBy(
                          groupedMetersWithBestOffer,
                          ({ bestOfferPeriod }) =>
                            sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                            (12 / Number(bestOfferPeriod?.periodName))
                        ) >
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )
                          ? '#c62828'
                          : '#1b5e20'
                    }}
                  >
                    $
                    {formatNumber(
                      sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ) -
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        ),
                      0,
                      '-'
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontWeight: 'bold',
                      bgcolor:
                        sumBy(
                          groupedMetersWithBestOffer,
                          ({ bestOfferPeriod }) =>
                            sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                            (12 / Number(bestOfferPeriod?.periodName))
                        ) >
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )
                          ? '#ef9a9a'
                          : '#a5d6a7',
                      color:
                        sumBy(
                          groupedMetersWithBestOffer,
                          ({ bestOfferPeriod }) =>
                            sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                            (12 / Number(bestOfferPeriod?.periodName))
                        ) >
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )
                          ? '#c62828'
                          : '#1b5e20'
                    }}
                  >
                    {formatNumber(
                      ((sumBy(
                        groupedMetersWithBestOffer,
                        ({ bestOfferPeriod }) =>
                          sumBy(bestOfferPeriod.splitPeriods, (item) => item.totalCosts) *
                          (12 / Number(bestOfferPeriod?.periodName))
                      ) -
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )) /
                        sumBy(groupedMeters, ({ meters }) =>
                          sumBy(meters, (meter) =>
                            sum([
                              meter.annualData.contestableCost,
                              meter.annualData.networkStanding,
                              meter.annualData.networkChargesEnergy,
                              meter.annualData.networkChargesDemand,
                              meter.annualData.regulatedCost
                            ])
                          )
                        )) *
                        100,
                      2,
                      '-'
                    )}
                    %
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </SectionContainer>
      </Section>
    </>
  );
};

export default SummarySection;
